import React from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import Heading from "../Elements/Heading"
import LinkMore from "../Elements/LinkMore"
import Breadcrumbs from "../Breadcrumbs"
import { getFeaturedImage } from "../../helpers/images"

import * as styles from "./casesBlock.module.css"
import { CaseArrow } from "../../assets/images"

const CasesBlock = ({
  showMore = false,
  limitItems = 0,
  pageContext,
  home,
}) => {
  const { t } = useTranslation()
  const { allWpCase } = useStaticQuery(
    graphql`
      query {
        allWpCase(
          filter: { status: { eq: "publish" } }
          sort: { order: ASC, fields: menuOrder }
        ) {
          nodes {
            title
            excerpt
            link
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let cases = allWpCase?.nodes
  if (limitItems) {
    cases = allWpCase?.nodes?.slice(0, limitItems)
  }

  return (
    <div className={cx(styles.container, home && styles.homePage)}>
      {pageContext ? (
        <div
          className={cx(styles.inner, styles.innerBreadcrumbs, "page_titles")}
        >
          <Breadcrumbs pageContext={pageContext} />
          <Heading pageTitle>{t("Наши кейсы")}</Heading>
        </div>
      ) : (
        <div className={styles.inner}>
          <Heading>{t("Наши кейсы")}</Heading>
        </div>
      )}
      <div className={styles.catalog}>
        {cases
          ?.filter(item => item.link.indexOf("-draft") === -1)
          ?.map((item, index) => (
            <div className={styles.caseItem} key={index}>
              <div className={styles.caseBackdrop}>
                {getFeaturedImage(item)}
              </div>
              <Link
                className={styles.caseLink}
                to={item.link.replace("/case/", "/cases/")}
              >
                <span className={styles.caseWrapper}>
                  <span className={styles.title}>{item.title}</span>
                  <span className={styles.arrow}>
                    <img className={styles.arrowImg} src={CaseArrow} alt="" />
                  </span>
                  <span
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: item.excerpt }}
                  />
                </span>
              </Link>
            </div>
          ))}
      </div>
      {showMore && (
        <div className={cx(styles.inner, styles.innerPadding)}>
          <LinkMore link="/cases/">{t("Больше проектов")}</LinkMore>
        </div>
      )}
    </div>
  )
}

export default CasesBlock
